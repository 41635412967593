import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import Header from './Layout/Nav3';
import Footer from './Layout/Footer1';
import Home from './Home';
import Farebox from './Farebox';
import Performance from './Performance';
import Airlines from './Airlines';
import News from './News';
import NewsDetail from './News/detail.jsx';
import Company from './Company';
import Contact from './Contact';
import PrivacyPolicy from './Privacy-policy';
import GeneralConditions from './General-conditions';

import {
  Nav30DataSource,
  Footer10DataSource,
} from './utils/data.source.js';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }
  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }


  render() {
    return (
      <Router>
        <div>
          <Header dataSource={Nav30DataSource} isMobile={!!this.state.isMobile} />
          <Routes>
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/product" element={<Farebox />} />
            <Route exact path="/performance" element={<Performance />} />
            <Route exact path="/content" element={<Airlines />} />
            <Route exact path="/company" element={<Company />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/general-conditions" element={<GeneralConditions />} />
            <Route path="/newsDetail/:id" element={<NewsDetail />} />
            <Route path="/new" element={<Navigate to="/news" />} />
            <Route path="/news" element={<News />} />
            <Route path="/" element={<Navigate to="/home" />} />
          </Routes>
          <Footer dataSource={Footer10DataSource} isMobile={this.state.isMobile} />
        </div>
      </Router>
    );
  }
}

export default App;
